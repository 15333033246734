<template>
  <v-container :class="{ 'pr-0 pl-0 pt-0': !$vuetify.breakpoint.mdAndUp }">
    <v-row>
      <v-col cols="12" class="mt-2">
        <h3 class="text-h5 font-weight-medium text-center">
          Limite exedido
        </h3>
        <p class="ma-0 mt-2 ml-1 text-justify">
          La cantidad de productos que puedes registrar en tu inventario fue
          <span class="font-weight-bold">excedida</span>, si deseas actualizar a
          un nuevo plan o subir la cantidad total de productos que puedes
          registrar, por favor contacta con soporte para que te brinde una
          atención personalizada.
        </p>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn
          small
          outlined
          href="mailto:edwin@ssencial.com?subject=Actualizar%20plan"
          >Contactar</v-btn
        >
      </v-col>
      <v-col cols="12">
        <h3 class="text-h6 mb-2 ">Plan actual</h3>
        <div class="text-center" v-if="loader">
          <v-progress-circular indeterminate></v-progress-circular>
        </div>
        <v-expand-transition>
          <v-card
            v-if="!loader"
            outlined
            class="text-center d-flex flex-column justify-center"
            :max-width="$vuetify.breakpoint.width < 673 ? '100%' : '320'"
          >
            <v-container class="d-flex align-center justify-center">
              <div class="d-flex align-center">
                <img
                  v-if="$vuetify.theme.dark"
                  src="../../assets/ssencial-logo-white.svg"
                  alt="ssencial"
                  height="18px"
                />
                <img
                  v-else
                  src="../../assets/ssencial-logo.svg"
                  alt="ssencial"
                  height="18px"
                />
                <!-- <v-img src="" width="30" height="30" contain></v-img> -->
                <p class="text-h5 ma-0 ml-1">{{ plan.type }}</p>
              </div>
            </v-container>
            <v-divider></v-divider>
            <v-container class="px-5">
              <p class="ma-0 font-weight-light">{{ plan.description }}</p>
            </v-container>
            <v-container class="px-5">
              <template v-for="(feature, index) in plan.features">
                <p class="ma-0 font-weight-medium" :key="index + '-title'">
                  {{ feature.title }}
                </p>
                <p
                  :key="index + '.-desc'"
                  class="caption mb-0"
                  :class="{ 'mb-3': plan.features.length > index + 1 }"
                >
                  {{ feature.decription }}
                </p>
              </template>
            </v-container>
          </v-card>
        </v-expand-transition>
      </v-col>
      <v-col cols="12" class="mt-2">
        <v-btn to="/" block class="primary">Ir al inicio</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      plan: null,
      loader: true
    };
  },
  beforeMount() {
    this.getCurrentPlanData();
  },
  methods: {
    ...mapActions("business", ["getBusinessPlan", "getPlanData"]),
    async getCurrentPlanData() {
      this.loader = true;
      if (this.getBusinessPlanID) {
        this.plan = await this.getPlanData(this.getBusinessPlanID);
        this.loader = false;
      } else {
        const planData = await this.getBusinessPlan();
        this.plan = await this.getPlanData(planData.id);
        this.loader = false;
      }
    }
  },

  computed: {
    ...mapGetters("business", ["getBusinessPlanID"])
  }
};
</script>

<style></style>
